<template>
  <div class="my-second xuqiu">
      <Head :title="$t('xuqiu.myneeds')" :show="true" ></Head>
      <div class="common-box xuqiu-box" v-if="show">

          <div class="xuqiu-list" v-if="list">
              <div class="xuqiu-list-item" v-for="(l,i) in list" :key="i" @click="seeDetail(l)">
                  <div class="xuqiu-list-item-top">
                      <div class="xuqiu-list-item-top-left">
                         <p class="error-cate ">{{l.category_name}}</p>
                         <p class="error-status " :class="'status'+(l.status)">
                             <span v-if="l.status==0">{{$t('xuqiu.not_resolve')}}</span>
                             <span v-if="l.status==1">{{$t('xuqiu.have_resolve')}}</span>
                             <span v-if="l.status==2">{{$t('xuqiu.loading_resolve')}}</span>
                         </p>
                      </div>
                      <p class="error-time">
                        {{l.time}}
                      </p>
                  </div>
                  <h3 class="error-title">{{l.subject}}</h3>
                  <div class="error-content">
                    {{l.content}}
                  </div>
                  <div class="error-imgs">
                    <img :src="$host+l.picture" alt="">
                  </div>
              </div>
          </div>

          <div v-else class="no-box">
                <img class="juan-no" src="../../../assets/images/xuqiu-no.png" alt="">
                <p class="no-text">{{$t('xuqiu.no_resolve')}}</p>
          </div>
      </div>
  </div>
</template>


<script>
export default {
    data(){
        return {
            list:null,
            show:false
        }
    },
    methods:{
        seeDetail(item){
            this.$router.push({
                name:"mine-xuqiudetail",
                query:{
                    qid:item.qid
                }
            })
        }
    },
    mounted(){
        this.$ajax.helplist()
        .then(res=>{
            if(res.code==200){
                this.list = res.data.list;
                this.show = true;
            }
        })
    }
}
</script>
